import React, { useEffect } from "react";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

import Place from "@material-ui/icons/Place";
import Forward from "@material-ui/icons/Forward";
import FormLabel from "@material-ui/core/FormLabel";
import CustomInput from "components/CustomInput/CustomInput.js";
import SearchIcon from "@material-ui/icons/Search";
import Delete from "@material-ui/icons/Delete";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import setAuthToken from "../../utils/setAuthToken";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const classes = useStyles();
  const [polos, setPolos] = React.useState([]);
  const [txtSearch, setTxtSearch] = React.useState("");

  // CARGA DE LA RONDA
  const fetchData = async () => {
    // setAuthToken(localStorage.jwtToken);
    const result = await axios.get(
      `https://epstool.com/api/polos/all`
      // `/api/polos/all`
    );
    setPolos(result.data);
  };

  const getQueryVariable = async (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  };

  // CARGA INICIAL
  useEffect(() => {
    fetchData();
    if (!localStorage.jwtToken) {
      getQueryVariable("token")
        .then((token) => {
          if (token) {
            setAuthToken(token);
            localStorage.setItem("jwtToken", token);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  // CARGA DE LA RONDA
  const searchValvula = async (numValvula) => {
    if (txtSearch) {
      // setAuthToken(localStorage.jwtToken);
      const result = await axios.get(
        `https://epstool.com/api/polos/${numValvula}`
        // `/api/polos/${numValvula}`
      );
      setPolos(result.data);
    }
  };

  let tbPolos;
  Object.keys(polos).length >= 1
    ? (tbPolos = polos.map((polo) => {
        return {
          id: polo._id,
          uuid: polo.uuid,
          ubicacion: polo.ubicacion,
          area: polo.areaProductiva,
          actions: (
            <div className="actions-right">
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  props.history.push(`polo/${polo.codigo}/${polo.ubicacion}`);
                }}
                color="success"
                className="edit"
              >
                <Forward />
              </Button>{" "}
            </div>
          ),
        };
      }))
    : (tbPolos = []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12}>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <Place />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Lista de Polos</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel className={classes.labelCustomHorizontal}>
                  Buscar por valvula de cierre:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={5}>
                <CustomInput
                  labelText="# de valvula"
                  id="valvula"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => {
                      setTxtSearch(event.target.value);
                    },
                    type: "text",
                    value: txtSearch,
                  }}
                />
              </GridItem>
              <GridItem xs={5} sm={1}>
                <Button
                  simple
                  className={classes.btnSearch}
                  color="success"
                  onClick={() => {
                    searchValvula(txtSearch.toUpperCase());
                  }}
                >
                  <SearchIcon className={classes.icons} /> Buscar
                </Button>
              </GridItem>
              <GridItem xs={6} sm={1}>
                <Button
                  simple
                  className={classes.btnSearch}
                  color="danger"
                  onClick={() => {
                    fetchData();
                  }}
                >
                  <Delete className={classes.icons} /> Resetear
                </Button>
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12}>
                <ReactTable
                  columns={[
                    {
                      Header: "Codigo",
                      accessor: "uuid",
                    },
                    {
                      Header: "Ubicacion",
                      accessor: "ubicacion",
                    },
                    {
                      Header: "Area",
                      accessor: "area",
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                    },
                  ]}
                  data={tbPolos}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

import Dashboard from "views/Dashboard/Dashboard.js";
import BMS from "views/Apps/Apps";
import LoginPage from "views/Pages/LoginPage.js";
import CrearPolos from "views/Polos/crearPolo.js";
import PoloDetails from "views/Polos/poloDetails.js";
import PoloEdit from "views/Polos/editPolo.js";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Image from "@material-ui/icons/Image";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },

  {
    collapse: true,
    invisible: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: Image,
    state: "pageCollapse",
    views: [
      {
        path: "/login-page",
        name: "Login Page",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: LoginPage,
        layout: "/auth",
      },
    ],
  },
  {
    privacidad: true,
    path: "/crearpolo",
    name: "Crear Polos",
    mini: "B",
    rtlMini: "ب",
    component: CrearPolos,
    layout: "/admin",
  },
  {
    privacidad: true,
    path: "/apps",
    name: "Aplicaciones DMS",
    rtlName: "لوحة القيادة",
    icon: Apps,
    component: BMS,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/polo/:codigo/:zona",
    name: "Polo",
    mini: "P",
    rtlMini: "ب",
    component: PoloDetails,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/editar/:codigo/:zona",
    name: "Polo",
    mini: "P",
    rtlMini: "ب",
    component: PoloEdit,
    layout: "/admin",
  },
];

export default dashRoutes;

import React, { useState, useEffect } from "react";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import AddCircle from "@material-ui/icons/AddCircle";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Danger from "components/Typography/Danger";
import Divider from "@material-ui/core/Divider";

// import Divider from "@material-ui/core/Divider";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

export default function EditPolo(props) {
  const classes = useStyles();

  const [codigo, setCodigo] = React.useState("");
  const [id, setId] = React.useState("");
  const [serie, setSerie] = React.useState("");
  const [ubicacion, setUbicacion] = React.useState("");
  const [area, setArea] = React.useState("");
  const [tipo, setTipo] = React.useState("");
  const [encargado, setEncargado] = React.useState("");
  const [errTipoName, setErrTipoName] = React.useState("");
  const [errors, setErrors] = React.useState([
    { id: 0, name: "", tipo: "", obligatorio: "" },
  ]);
  const codigoPolo = props.match.params.codigo;
  const zona = props.match.params.zona;

  const fetchData = async () => {
    // setAuthToken(localStorage.jwtToken);
    const result = await axios.get(
      `https://epstool.com/api/polos/${codigoPolo}/${zona}`
      // `/api/polos/${codigoPolo}/${zona}`
    );
    setCodigo(result.data.codigo);
    setId(result.data._id);
    setUbicacion(result.data.ubicacion);
    setArea(result.data.areaProductiva);
    setTipo(result.data.tipo);
    setSerie(result.data.serie);
  };

  // CARGA INICIAL
  useEffect(() => {
    fetchData();
  }, []);

  const handleTipoSubmit = async (event) => {
    event.preventDefault();
    // let flag = verificar(form);
    // if (flag === 0) {
    const poloToSave = {
      codigo: codigo,
      serie: serie,
      ubicacion: ubicacion,
      areaProductiva: area,
      tipo: tipo,
      encargado: encargado,
    };
    await axios
      .post(`https://epstool.com/api/polos/editarPolo/${id}`, poloToSave)
      // .post(`/api/polos/editarPolo/${id}`, poloToSave)
      .then((res) => {
        props.history.push(`../../polo/${codigo}/${ubicacion}`);
      })
      .catch((error) => {
        // setErrors(console.log(error));
        console.log(error);
      });
    // }
  };

  return (
    <GridContainer>
      <Card>
        <CardHeader color="success" text>
          <CardText color="success">
            <h4 className={classes.cardTitle}>Crear nuevo polo</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <div className={classes.cardHeader}>
            <h4 className={classes.cardTitle}>Datos Generales</h4>
          </div>
          {/* Codigo Polo */}
          <GridContainer>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelCustomHorizontal}>
                Codigo del polo:
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <CustomInput
                labelText="Ej: 1000"
                id="codPolo"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => {
                    setCodigo(event.target.value);
                  },
                  type: "number",
                  value: codigo,
                }}
              />
              {/* <Danger>
                  {errTipoName === undefined ? null : (
                    <div className="invalid-feedback">{errTipoName}</div>
                  )}
                </Danger> */}
            </GridItem>
            <GridItem xs={12} xs={6}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Tipo*
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Tipo
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="SENCILLO"
                  >
                    SENCILLO
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="DOBLE"
                  >
                    DOBLE
                  </MenuItem>
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelCustomHorizontal}>
                Area Productiva:
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <CustomInput
                labelText="Ej: Laser"
                id="area"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => {
                    setArea(event.target.value);
                  },
                  type: "text",
                  value: area,
                }}
              />
              {/* <Danger>
                  {errTipoName === undefined ? null : (
                    <div className="invalid-feedback">{errTipoName}</div>
                  )}
                </Danger> */}
            </GridItem>
            <GridItem xs={12} xs={6}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Ubicación*
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={ubicacion}
                  onChange={(e) => setUbicacion(e.target.value)}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Ubicación
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="CER 1"
                  >
                    CER 1
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="CER 2"
                  >
                    CER 2
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="CER 3"
                  >
                    CER 3
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="CER 4"
                  >
                    CER 4
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="CER 5"
                  >
                    CER 5
                  </MenuItem>
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelCustomHorizontal}>
                Encargado:
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <CustomInput
                labelText="Ej: FusionTech"
                id="encargado"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => {
                    setEncargado(event.target.value);
                  },
                  type: "text",
                  value: encargado,
                }}
              />
              {/* <Danger>
                  {errTipoName === undefined ? null : (
                    <div className="invalid-feedback">{errTipoName}</div>
                  )}
                </Danger> */}
            </GridItem>
            <GridItem xs={12} sm={1}>
              <FormLabel className={classes.labelCustomHorizontal}>
                Serie:
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={5}>
              <CustomInput
                labelText="Ej: 1010"
                id="serie"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => {
                    setSerie(event.target.value);
                  },
                  type: "text",
                  value: serie,
                }}
              />
              {/* <Danger>
                  {errTipoName === undefined ? null : (
                    <div className="invalid-feedback">{errTipoName}</div>
                  )}
                </Danger> */}
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={6}>
              <Button fullWidth color="success" onClick={handleTipoSubmit}>
                {/* <Button fullWidth color="success"> */}
                Guardar Cambios
              </Button>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Button
                fullWidth
                color="danger"
                onClick={() => {
                  props.history.push(`../../polo/${codigo}/${ubicacion}`);
                }}
              >
                {/* <Button fullWidth color="success"> */}
                Cancelar
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
